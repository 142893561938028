import React from 'react';
import SEO from '../../components/seo';
import CategoryPage from '../../components/desktop/Categories/CategoryPage';
import {graphql, StaticQuery} from 'gatsby';

const Category = () => {
  return (
    <>
      <SEO title={'Pozostałe dzieła'} />
      <StaticQuery
        query={graphql`
          query {
            api {
              arts {
                title {
                  pl
                }
                titlePage
                photos
                category
                thumbnail
              }
            }
          }
        `}
        render={(data) => (
          <CategoryPage
            data={data.api.arts}
            categoryId={4}
            location="pl"
            bgColor="#AFA3C0"
          />
        )}
      />
    </>
  );
};

export default Category;
